import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatCardModule } from '@angular/material/card';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthGmsAngularClientModule } from '@coin/modules/auth/feature';
import { GbrDocumentsService, GbrService } from '@coin/modules/gbr/data-access';
import { GbrPersonSaveService } from '@coin/modules/gbr/data-management';
import { LoadingModule } from '@coin/shared/data-access';
import { LegacyComponentsModule } from '@coin/shared/feature-legacy-components';
import { V2CheckboxComponent, V2NumberInputComponent, V2TextInputComponent, V2DividerComponent } from '@coin/shared/ui-storybook';
import { NumberFormatCountryIso } from '@coin/shared/util-enums';
import { DateTimezoneFixPipe } from '@coin/shared/util-pipes';
import { TranslateModule } from '@ngx-translate/core';
import { InputOverviewComponent } from './gbr-request-dialog/components/input-overview/input-overview.component';
import { PersonViewComponent } from './gbr-request-dialog/components/person-view/person-view.component';
import { PersonalDataComponent } from './gbr-request-dialog/components/personal-data/personal-data.component';
import { GbrRequestDialogComponent } from './gbr-request-dialog/gbr-request-dialog.component';
import { FileInputComponent } from './gbr-request-dialog/inputs/file-input/file-input.component';
import { LocationInputComponent } from './gbr-request-dialog/inputs/location-input/location-input.component';
import { PersonInputComponent } from './gbr-request-dialog/inputs/person-input/person-input.component';
import { SimpleGbrInputComponent } from './gbr-request-dialog/inputs/simple-gbr-input/simple-gbr-input.component';

@NgModule({
  declarations: [
    GbrRequestDialogComponent,
    PersonalDataComponent,
    PersonInputComponent,
    LocationInputComponent,
    InputOverviewComponent,
    PersonViewComponent,
    FileInputComponent,
    SimpleGbrInputComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    DateTimezoneFixPipe,
    LegacyComponentsModule,
    LoadingModule,
    V2CheckboxComponent,
    // Material Imports
    MatIconModule,
    MatListModule,
    MatStepperModule,
    MatCardModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatMomentDateModule,
    V2NumberInputComponent,
    V2TextInputComponent,
    V2DividerComponent
  ],
  exports: [GbrRequestDialogComponent, SimpleGbrInputComponent],
  providers: [
    GbrDocumentsService,
    GbrService,
    GbrPersonSaveService,
    { provide: MAT_DATE_LOCALE, useValue: NumberFormatCountryIso.German },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    }
  ]
})
export class LibGbrModule {
  static forTest() {
    return LibGbrTestModule;
  }
}

@NgModule({ imports: [LibGbrModule, AuthGmsAngularClientModule.forTest()] })
class LibGbrTestModule {}
