<div class="location-input-wrapper" #wrapper tabindex="-1">
  <mat-card class="mat-mdc-elevation-specific mat-elevation-z3" [class.active]="hasFocus" [class.collapsed]="data?.sameAsEmployee">
    <div class="label">{{ 'gbr.location.same-label' | translate }}</div>

    <div class="main-part" (mousedown)="toggleEdit()">
      <p class="value">{{ selectionLabel }}</p>
      <div class="icon">
        <mat-icon>{{ hasFocus ? 'expand_less' : 'expand_more' }}</mat-icon>
      </div>
    </div>

    <div class="edit-part" tabindex="-1" #editPart>
      <div class="yes-no-option">
        <div class="yes">
          <coin-v2-checkbox [ngModel]="data?.sameAsEmployee" (ngModelChange)="onSameToggle(!data?.sameAsEmployee)" [label]="'general.yes' | translate"> </coin-v2-checkbox>
        </div>
        <div class="no">
          <coin-v2-checkbox [ngModel]="!data?.sameAsEmployee" (ngModelChange)="onSameToggle(!data?.sameAsEmployee)" [label]="'general.no' | translate"> </coin-v2-checkbox>
        </div>
      </div>
      <div class="location-inputs" [class.active]="!data?.sameAsEmployee">
        @if (data && !data?.sameAsEmployee) {
          <coin-v2-text-input class="street" icon="edit" [placeholder]="'gbr.entry' | translate" [label]="'gbr.location.street' | translate" matFormFieldClass="shadow" [(ngModel)]="data.street" />
          <coin-v2-text-input class="number" icon="edit" [placeholder]="'gbr.entry' | translate" [label]="'gbr.location.number' | translate" matFormFieldClass="shadow" [(ngModel)]="data.number" />
          <coin-v2-number-input
            class="postalcode"
            svgIcon="edit"
            [placeholder]="'gbr.entry' | translate"
            [label]="'gbr.location.postalcode' | translate"
            [(ngModel)]="data.postalCode">
          </coin-v2-number-input>
        }
      </div>
    </div>
  </mat-card>
</div>
