import { TinyHelpers } from './tiny-helpers';

export class DownloadFileHelper {
  public static download(base64: string, name = 'coin-download'): void {
    if (!base64) {
      return;
    }

    const link = document.createElement('a');
    link.href = base64;
    link.download = name;
    document.body.append(link);
    link.click();
    link.remove();
  }

  public static extractFile(target: EventTarget): File {
    TinyHelpers.assert(target instanceof HTMLInputElement);
    const file = target.files[0];
    TinyHelpers.assert(!!file);
    target.value = '';
    return file;
  }
}
