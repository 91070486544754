<mat-form-field
  [matTooltip]="errorTooltip$ | async"
  [matTooltipDisabled]="!touched()"
  matTooltipClass="error-tooltip"
  class="lib-input coin-form-field-outline coin-form-field-outline--{{ matFormFieldClass }} no-margin"
  appearance="outline"
  floatLabel="always">
  @if (label) {
    <mat-label>{{ label }}</mat-label>
  }
  <input
    #numberInput
    type="number"
    (blur)="onTouch(value)"
    [placeholder]="placeholder"
    [attr.step]="step"
    [attr.min]="min"
    [attr.max]="max"
    [readonly]="readonly"
    matInput
    (ngModelChange)="onValueChange($event)"
    [ngModel]="value"
    [disabled]="disabled"
    autocomplete="off" />
</mat-form-field>
