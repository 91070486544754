import { Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AsyncPipe } from '@angular/common';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { DefaultControlValueAccessor } from '@coin/shared/util-helpers';
import { MatTooltip } from '@angular/material/tooltip';
import { MatFormFieldClass } from '../../input.types';

@Component({
  selector: 'coin-v2-number-input',
  templateUrl: './v2-number-input.component.html',
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, ReactiveFormsModule, FormsModule, MatTooltip, AsyncPipe],
  styleUrls: ['./v2-number-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => V2NumberInputComponent),
      multi: true
    }
  ]
})
export class V2NumberInputComponent extends DefaultControlValueAccessor<number> {
  @Input() label: string;
  @Input() placeholder: string;
  @Input() matFormFieldClass: MatFormFieldClass = 'customer-grey';
  @Input() step?: number;
  @Input() enforceStep = false;
  @Input() min?: number;
  @Input() max?: number;
  @Input() enforceMinMax = false;
  @Input() readonly?: boolean;

  @ViewChild('numberInput') private numberInput: ElementRef<HTMLInputElement>;

  public focus(): void {
    this.numberInput?.nativeElement?.focus();
  }

  public onValueChange(value: number): void {
    let enforcedValue = value;

    if (this.enforceStep && this.step && enforcedValue % this.step !== 0) {
      enforcedValue = Math.ceil(enforcedValue / this.step) * this.step;
    }

    if (this.enforceMinMax) {
      if (this.min != null && enforcedValue < this.min) {
        enforcedValue = this.min;
      } else if (this.max != null && enforcedValue > this.max) {
        enforcedValue = this.max;
      }
    }

    this.value = enforcedValue;
    this.cd.markForCheck();
  }
}
