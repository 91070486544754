<div class="gbr-person-input-wrapper">
  <mat-card class="mat-mdc-elevation-specific mat-elevation-z4" [class.edit]="edit">
    <div class="empty-profile">
      <img src="/assets/images/gbr/add-profile-2.png" />
      @if (!edit) {
        <p class="text">
          {{ (isChildInput ? 'gbr.person-input.child-info' : 'gbr.person-input.adult-info') | translate }}
        </p>
      } @else {
        <p>{{ 'gbr.person-input.fill-info' | translate }}</p>
      }

      <div style="flex: 1"></div>
      <div class="action-buttons">
        @if (!edit) {
          <coin-action-button class="primary" icon="add" (click)="toggleEdit()" [disabled]="disabled">
            {{ 'gbr.add' | translate }}
          </coin-action-button>
        } @else {
          <coin-action-button class="primary save-button" icon="save" (click)="save()">
            {{ 'gbr.save' | translate }}
          </coin-action-button>
          <coin-action-button class="secondary icon-button" [noicon]="true" (click)="toggleEdit()">
            <mat-icon>close</mat-icon>
          </coin-action-button>
        }
      </div>
    </div>

    @if (value || display) {
      <div class="selected-person" @flyInFromBottom>
        <coin-siemens-energy-person-view [value]="value" [display]="display" [type]="type" (edit)="edit ? save() : toggleEdit()" (remove)="removeClick()" [disabled]="disabled">
        </coin-siemens-energy-person-view>
      </div>
    }

    @if (!display) {
      <div class="edit-profile">
        @if (!isChildInput) {
          <div class="multiple-inputs">
            <coin-v2-text-input
              icon="edit"
              [placeholder]="'gbr.entry' | translate"
              [label]="'gbr.person-input.firstname' | translate"
              matFormFieldClass="shadow"
              [(ngModel)]="$any(formPerson).firstname" />
            <coin-v2-text-input
              icon="edit"
              [placeholder]="'gbr.entry' | translate"
              [label]="'gbr.person-input.lastname' | translate"
              matFormFieldClass="shadow"
              [(ngModel)]="$any(formPerson).lastname" />
          </div>
          <coin-siemens-energy-location-input [data]="$any(formPerson).location"></coin-siemens-energy-location-input>
          <div class="gid-input">
            <coin-v2-text-input
              icon="search"
              [placeholder]="'gbr.entry' | translate"
              [label]="'gbr.person-input.gid' | translate"
              matFormFieldClass="shadow"
              [(ngModel)]="$any(formPerson).gidOrEmail" />
          </div>
        } @else {
          <div class="multiple-inputs">
            <mat-form-field
              class="coin-form-field-outline coin-form-field-outline--shadow coin-form-field-outline--date"
              appearance="outline"
              floatLabel="always"
              (click)="rangePicker.open()">
              <mat-label>{{ 'gbr.person-input.birthday' | translate }}</mat-label>
              <input matInput [matDatepicker]="rangePicker" [placeholder]="'gbr.entry' | translate" matInput [(ngModel)]="$any(formPerson).birthday" readonly />
              <mat-icon matSuffix>date_range</mat-icon>
              <mat-datepicker #rangePicker></mat-datepicker>
            </mat-form-field>

            <coin-dropdown
              class="dropdown"
              [label]="'gbr.person-input.same-household' | translate"
              [items]="yesNoOptions"
              [(ngModel)]="$any(formPerson).sameHousehold"
              translateMe="gbr.person-input.">
            </coin-dropdown>
            <coin-dropdown
              class="dropdown"
              [label]="'gbr.person-input.in-education' | translate"
              [items]="yesNoOptions"
              [(ngModel)]="$any(formPerson).inEducation"
              translateMe="gbr.person-input.">
            </coin-dropdown>
          </div>
        }
      </div>
    }
  </mat-card>
</div>
